.info {
  height: 100%;
  position: relative;
  z-index: 0;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 50px 36px 70px;
  position: relative;
}

.formBackground {
  position: absolute;
  z-index: -1;
  background: var(--back-gradient);
  inset: 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    top: -153px;
    right: -94px;
    animation: animate 15s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    left: -84px;
    right: 0;
    bottom: -50px;
    animation: animate 30s linear infinite;
  }
}

@keyframes animate {
  0% {
    transform: translateY(100px);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(100px);
  }
}

.image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.title {
  color: #f8f6ff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.subtitle {
  color: #f8f6ff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.body {
  margin: -26px 0 0;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 37px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  // min-height: 50vh;
  // gap: 60px;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #d7ddf0;
  background-image: url(../../assets/morePage/morePage_arrow.svg);
  // background-image: url(../../../../../assets/morePage/morePage_arrow.svg);
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding: 3px 30px 3px 0;
}

.container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  // border-bottom: 1px solid #d7ddf0;
  padding: 3px 0;
}

.profile {
  width: 29px;
  height: 29px;
  background: url(../../assets/morePage/morePage_person.svg);
}

.services {
  width: 29px;
  height: 29px;
  background: url(../../assets/morePage/morePage_service.svg);
}

.reports {
  width: 29px;
  height: 29px;
  background: url(../../assets/morePage/morePage_report.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.settings {
  width: 29px;
  height: 29px;
  background: url(../../assets/morePage/morePage_setting.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.password {
  width: 29px;
  height: 29px;
  background: url(../../assets/morePage/morePage_editPassword.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.rixap {
  width: 29px;
  height: 29px;
  background: url(../../assets/morePage/morePage_rixapLogo.svg);
  background-size: 29px 29px;
  background-position: center;
  background-repeat: no-repeat;
}

.text {
  color: #363942;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.exitContainer {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.exitLogo {
  width: 24px;
  height: 24px;
  background: url(../../assets/morePage/morePage_exit.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.exitText {
  color: #ea5552;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
}
