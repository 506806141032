.page {
  display: flex;
  flex-direction: column;
  background: #fff;
  // min-height: 100vh;
  position: relative;
  padding: 64px 0 0;
  left: 0;
  right: 0;
  // overflow-y: scroll;

  // &::-webkit-scrollbar {
  //   height: 0;
  //   width: 0;
  //   padding: 0;
  // }

  &_blocked {
    position: fixed;
    z-index: 5;
  }
}

.newTask {
  position: fixed;
  bottom: 100px;
  right: 20px;
  border-radius: 60px;
  background: var(--back-gradient);
  width: 60px;
  height: 60px;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  &::after {
    content: "";
    width: 24px;
    height: 24px;
    background: url(../../../../assets/plus.svg);
  }
}
