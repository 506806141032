.page {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 70px;
  background: #fff;
  min-height: 100vh;
  overscroll-behavior: contain;
}

.body {
  display: flex;
  overscroll-behavior: contain;
  overflow-y: auto;
  // height: 100%;
  max-height: calc(100vh - 134px);
  height: 100%;
  max-width: 100%;
  position: relative;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}

.reports {
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  display: flex;
  // width: 100%;
  overscroll-behavior: contain;
  overflow-y: auto;
  max-height: calc(100vh - 134px);
  width: 100%;
  max-width: 100%;
  border-left: 1px solid #d9d9d9;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}

.comments {
  // min-width: 415px;
  width: 100%;
}

.appInfo {
  height: 100%;
  max-width: 433px;
  width: 100%;
  position: relative;
  border-left: 1px solid #d9d9d9;
  max-height: calc(100vh - 134px);
  overflow-y: auto;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}
